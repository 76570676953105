export default class Configuration {

  public static get Instance(): Configuration {
    if (Configuration.instance === undefined) {
      Configuration.instance = new Configuration();
      Configuration.loadDataFromDOM(Configuration.instance);
    }
    return Configuration.instance;
  }

  public get SubscriptionType(): string { return this.subscriptionType; }
  public get UrlKey(): string { return this.urlKey; }
  public get UrlReason(): string { return this.urlReason; }
  public get SubscriptionTypeId(): string { return this.subscriptionTypeId; }
  public get ResourceId(): string { return this.resourceId; }
  public get ResourceValue(): string { return this.resourceValue; }
  public get Email(): string { return this.email; }
  public get ErrorMessage(): string { return this.errorMessage; }

  public get LoggedIn(): boolean { return this.loggedIn; }
  public get BrandName(): string { return this.brandName; }

  public get WasGloballyUnsubscribed(): boolean { return this.wasGloballyUnsubscribed; }

  private static instance: Configuration;

  private static loadDataFromDOM(instance: Configuration): void {
    const hdnUrlKey = document.getElementById('hdn-url-key') as HTMLInputElement;
    if (hdnUrlKey) {
      instance.urlKey = hdnUrlKey.value;
      hdnUrlKey.remove();
    }

    const hdnUrlReason = document.getElementById('hdn-url-reason') as HTMLInputElement;
    if (hdnUrlReason) {
      instance.urlReason = hdnUrlReason.value;
      hdnUrlReason.remove();
    }

    const hdnSubscriptionType = document.getElementById('hdn-subscription-type') as HTMLInputElement;
    if (hdnSubscriptionType) {
      instance.subscriptionType = hdnSubscriptionType.value;
      hdnSubscriptionType.remove();
    }

    const hdnSubscriptionTypeId = document.getElementById('hdn-subscription-type-id') as HTMLInputElement;
    if (hdnSubscriptionTypeId) {
      instance.subscriptionTypeId = hdnSubscriptionTypeId.value;
      hdnSubscriptionTypeId.remove();
    }

    const hdnResourceId = document.getElementById('hdn-resource-id') as HTMLInputElement;
    if (hdnResourceId) {
      instance.resourceId = hdnResourceId.value;
      hdnResourceId.remove();
    }

    const hdnResourceValue = document.getElementById('hdn-resource-value') as HTMLInputElement;
    if (hdnResourceValue) {
      instance.resourceValue = hdnResourceValue.value;
      hdnResourceValue.remove();
    }

    const hdnResourceEmail = document.getElementById('hdn-email') as HTMLInputElement;
    if (hdnResourceEmail) {
      instance.email = hdnResourceEmail.value;
      hdnResourceEmail.remove();
    }

    const hdnResourceErrorMessage = document.getElementById('hdn-error-message') as HTMLInputElement;
    if (hdnResourceErrorMessage) {
      instance.errorMessage = hdnResourceErrorMessage.value;
      hdnResourceErrorMessage.remove();
    }

    const hdnLoggedIn = document.getElementById('hdn-logged-in') as HTMLInputElement;
    if (hdnLoggedIn) {
      instance.loggedIn = JSON.parse(hdnLoggedIn.value.toLowerCase());
      hdnLoggedIn.remove();
    }

    const hdnBrandName = document.getElementById('hdn-brand-name') as HTMLInputElement;
    if (hdnBrandName) {
      instance.brandName = hdnBrandName.value;
      hdnBrandName.remove();
    }

    const hdnWasGloballyUnsubscribed = document.getElementById('hdn-was-globally-unsubscribed') as HTMLInputElement;
    if (hdnWasGloballyUnsubscribed) {
      instance.wasGloballyUnsubscribed = JSON.parse(hdnWasGloballyUnsubscribed.value.toLowerCase());
      hdnWasGloballyUnsubscribed.remove();
    }
  }

  private subscriptionType!: string;
  private urlKey!: string;
  private urlReason!: string;
  private subscriptionTypeId!: string;
  private resourceId!: string;
  private resourceValue!: string;
  private email!: string;
  private errorMessage!: string;

  private loggedIn!: boolean;
  private brandName!: string;
  private wasGloballyUnsubscribed!: boolean;
}
