
















































import '../scss/index.scss';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Button } from '@frontiers-vue/button';
import axios, { AxiosResponse, AxiosError } from 'axios';
import Configuration from '../core/Configuration';
import { IUnsubscribedData } from '../core/models';
// import Configuration from '@/core/Configuration';

@Component({
  components: { Button },
})
export default class Unsubscribe extends Vue {
  private isPendingToConfirm: boolean = true;
  private isUnsubscribing: boolean = false;
  private subscriptionTypeId: number = 0;
  private subscriptionType: string = '';
  private resourceValue: string = '';
  private resourceId: string = '';
  private email: string = '';
  private errorMessage: string = '';
  private preferencesKey: string = '';
  private urlKey: string = '';
  private urlReason: string = '';

  private reason: string = 'reason1';
  private showError: boolean = false;
  private showSuccess: boolean = false;
  private subscriptionTypeText: string = '';
  private unsubscribedSuccess: boolean = false;
  private showReactivateSuccess: boolean = false;
  private showReactivateError: boolean = false;

  private btn_unsubscribe(): void {
    this.isUnsubscribing = true;
    setTimeout(() => {
      this.isUnsubscribing = false;
      this.isPendingToConfirm = false;
    }, 2000);

    const data = { UrlKey: this.urlKey, UrlReason: this.urlReason };

    axios
      .post(`/emails/api/v1/unsubscribeToken`,
        data,
      )
      .then((response: AxiosResponse<IUnsubscribedData>) => {
        this.preferencesKey = response.data.preferencesKey;
        this.subscriptionTypeId = response.data.subscriptionTypeId;
        this.unsubscribedSuccess = response.data.unsubscribed;
      })
      .catch((error: AxiosError) => {
        this.unsubscribedSuccess = false;
      });
  }

  private created(): void {
    this.subscriptionType = Configuration.Instance.SubscriptionType;
    this.subscriptionTypeId = parseInt(Configuration.Instance.SubscriptionTypeId, 10);
    this.resourceId = Configuration.Instance.ResourceId;
    this.resourceValue = Configuration.Instance.ResourceValue;
    this.email = Configuration.Instance.Email;
    this.errorMessage = Configuration.Instance.ErrorMessage;
    this.urlKey = Configuration.Instance.UrlKey;
    this.urlReason = Configuration.Instance.UrlReason;

    if (this.subscriptionType !== '') {
      if (this.resourceValue !== undefined && this.resourceValue !== null && this.resourceValue.trim().length > 0) {
        this.subscriptionTypeText = this.subscriptionType + ' for ' + this.resourceValue;
      } else {
        this.subscriptionTypeText = this.subscriptionType;
      }
      this.isPendingToConfirm = true;
    } else {
      this.isPendingToConfirm = false;
      this.unsubscribedSuccess = false;
    }

  }

  private reactivateSubscription(): void {
    const data = {
      Key: this.$route.query.key
    };

    axios
      .post(`/emails/api/v1/reactivateSubscription`,
        data,
      )
      .then((response: AxiosResponse) => {
        this.showReactivateSuccess = true;
        this.showReactivateError = false;
      })
      .catch((reason: AxiosError) => {
        this.showReactivateSuccess = false;
        this.showReactivateError = true;
      });
  }

  private goToPreferences(): void {
    window.location.href = '/emails/preferences/' + encodeURIComponent(this.preferencesKey);
  }

  private focusTextarea(): void {
    const textarea = this.$refs.otherReason as HTMLElement;

    textarea.focus();
  }

  private activateOtherReason(): void {
    this.reason = 'otherReason';
  }

  private fakingErrorSucces(): void {
    if (!this.showError && !this.showSuccess) {
      this.showError = true;
    } else {
      this.showError = !this.showError;
      this.showSuccess = !this.showSuccess;
    }
  }
}
